<div class="container-fluid h-100">
    <div class="row">
        <div class="col p-0">
            <nav class="navbar bg-primary">
                <div class="container-fluid">
                  <a class="navbar-brand" href="#">
                    <h5 class="mb-0">
                        <img src="/assets/imgs/Bamas.png" style="height: 100% !important;" alt="" srcset="">
                    </h5>
                  </a>
                  <button class="btn btn-link text-white" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                    <i class="bi bi-list"></i>
                </button>
                </div>
              </nav>
        </div>
    </div>
    <div class="row mt-2 mb-2 align-items-center">
        <div class="col-6">
            <app-match-score-widget [matchScore]="matchScoreModel"></app-match-score-widget>
        </div>
        <div class="col-3">
            <div class="row">
    
                <div class="col text-center switch-select">
                    <label class="form-check-label me-2" for="flexSwitchCheckDefault">In Possession</label>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" [ngModel]="inOutPossession" (change)="goOutPossession()">
                      </div>
                      <label class="form-check-label" for="flexSwitchCheckDefault">Out Possession</label>
                    </div>
                
            </div>
        </div>
        <div class="col-3 text-end">
            <app-menu-bar-links-widget></app-menu-bar-links-widget>
        </div>
    </div>
    <div class="row phase-section" [ngStyle]="{'height':screenheight -(screenheight*0.29)+'px'}">
        <div class="col align-self-center">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="card card-body card-clear h-100">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col">
                                        <app-group-block-stats-widget [groupBlockStats]="counterAttackBlockModel"></app-group-block-stats-widget>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card card-body card-clear h-100">
                            <div class="container-fluid">
                                <div class="row mb-3">
                                    <div class="col">
                                        <app-bullet-stats-widget [blockStats]="buildUpBlockStats"></app-bullet-stats-widget> 
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <app-single-bar-stats-widget ></app-single-bar-stats-widget>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-bullet-stats-widget [blockStats]="progressionBlockStats"></app-bullet-stats-widget> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card card-body card-clear h-100">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col">
                                        <app-group-block-stats-widget [groupBlockStats]="finalthirdBlockModel"></app-group-block-stats-widget>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2 mb-2">
        <div class="col-2">

        </div>
        <div class="col-8">

        </div>
        <div class="col-2 text-end">
            &commat;2024 Datua, DATUA IA, S.A.
        </div>
    </div>
</div>