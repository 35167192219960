<div class="container-fluid">
    <div class="row align-items-center">
        <!-- Local Team-->
        <div class="col-5 p-3 local-team">
            <div class="row align-items-center">
                <div class="col-9 text-end">
                   <h3 class="mb-0"> {{matchScore.localTeam.displayName}}</h3>
                </div>
                <div class="col-3 text-center">
                    <img [src]="matchScore.localTeam.logoIcon" class="team-logo" alt="logo">
                </div>
            </div>
        </div>
        <!-- Score-->
        <div class="col-2 text-center">
            <h2 class="mb-0">
                {{matchScore.score.local}} - {{matchScore.score.visitor}}
            </h2>
        </div>
        <!-- Visitor Team-->
        <div class="col-5 p-3 visitor-team">
            <div class="row align-items-center">
                <div class="col-3 text-center">
                    <img [src]="matchScore.visitorTeam.logoIcon"  class="team-logo" alt="logo">
                </div>
                <div class="col-9">
                    <h3 class="mb-0"> {{matchScore.visitorTeam.displayName}}</h3>
                 </div>
            </div>
        </div>
    </div>
</div>