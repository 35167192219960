import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatchDashboardComponent } from './presentation/match-dashboard/match-dashboard.component';
import { InPossessionPageComponent } from './presentation/in-possession-page/in-possession-page.component';
import { OutPossessionPageComponent } from './presentation/out-possession-page/out-possession-page.component';
import { MatchVideosPageComponent } from './presentation/match-videos-page/match-videos-page.component';

const routes: Routes = [
  {path:'', component:MatchDashboardComponent},
  {path:'in-possession',component:InPossessionPageComponent},
  {path:'out-possession',component:OutPossessionPageComponent},
  {path:'match-videos',component:MatchVideosPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
