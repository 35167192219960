import { Component } from '@angular/core';
import { BlockStatsModel } from '../../domain/model/block-stats-model';
import { ChartConfiguration, ChartData, ChartEvent, ChartType, Color } from 'chart.js';
import { Router } from '@angular/router';
import { MatchScoreModel } from '../../domain/model/match-score-model';
import { TeamInformationModel } from '../../domain/model/team-information-model';
import { ScoreModel } from '../../domain/model/score-model';


@Component({
  selector: 'app-out-possession-page',
  templateUrl: './out-possession-page.component.html',
  styleUrl: './out-possession-page.component.scss',
})
export class OutPossessionPageComponent {
  screenheight:number;

  lowblockStats: BlockStatsModel;
  midBlockStats: BlockStatsModel;
  highPressStats: BlockStatsModel;
  counterPressStats: BlockStatsModel;
  recoveryStats: BlockStatsModel;

  inOutPossession:boolean = true;

  matchScoreModel:MatchScoreModel;

  localTeam:TeamInformationModel;
  visitorTeam:TeamInformationModel;
  score: ScoreModel;

  // Doughnut
  public doughnutChartLabels: string[] = [ 'High Press', 'Mid Block', 'Low Block' ];
  public doughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] = [
      { data: [ 4, 5, 9 ], 
        label: 'Events',
        backgroundColor: ['#006EC8','#1D2335','#C75D00'],
      borderWidth:0 
    },
    ];

  public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: true,
    
  };

  constructor(private _router:Router){
    this.screenheight = window.screen.height;
    this.lowblockStats = new BlockStatsModel('Low Block',46,25,9,'local','Low block');
    this.midBlockStats = new BlockStatsModel('Mid Block',46,25,4,'visitor','Mid block');
    this.highPressStats = new BlockStatsModel('High Press',46,25,5,'neutral','High press');
    this.counterPressStats = new BlockStatsModel('Counter-Press',46,25,14,'local','Counter-press');
    this.recoveryStats = new BlockStatsModel('Recovery',27,15,10,'visitor','Recovery');

    this.localTeam = new TeamInformationModel();
    this.localTeam.displayName = 'Deportivo Alavés B';
    this.localTeam.logoIcon = '/assets/imgs/teams/deportivo-alaves-b.png';
    this.localTeam.logoPath = '/assets/imgs/teams/deportivo-alaves-b.png';
    this.visitorTeam = new TeamInformationModel();
    this.visitorTeam.displayName = 'Barakaido';
    this.visitorTeam.logoIcon = '/assets/imgs/teams/barakaido.png';
    this.visitorTeam.logoPath = '/assets/imgs/teams/barakaido.png';
    this.score = new ScoreModel(2,1,90)
  
    this.matchScoreModel = new MatchScoreModel(this.localTeam,this.visitorTeam,this.score);
  }

  goInPossession(){
    this._router.navigate(['/in-possession'])
  }
}
