import { Component, Input } from '@angular/core';
import { DetailStatsModel } from '../../../domain/model/detail-stats-model';

@Component({
  selector: 'app-detail-stats-widget',
  templateUrl: './detail-stats-widget.component.html',
  styleUrl: './detail-stats-widget.component.scss'
})
export class DetailStatsWidgetComponent {

  @Input() detailStats:DetailStatsModel;

  constructor(){
    this.detailStats = new DetailStatsModel("BUILD-UP",0,0);
  }

}
