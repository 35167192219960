import { Component } from '@angular/core';
import { DetailStatsModel } from '../../domain/model/detail-stats-model';
import { MatchScoreModel } from '../../domain/model/match-score-model';
import { TeamInformationModel } from '../../domain/model/team-information-model';
import { ScoreModel } from '../../domain/model/score-model';
import { PossesionStatsModel } from '../../domain/model/possesion-stats-model';

@Component({
  selector: 'app-match-dashboard',
  templateUrl: './match-dashboard.component.html',
  styleUrl: './match-dashboard.component.scss'
})
export class MatchDashboardComponent {

  screenheight:number;

  matchScoreModel:MatchScoreModel;
  matchPossessionModel:PossesionStatsModel;

  localTeam:TeamInformationModel;
  visitorTeam:TeamInformationModel;
  score: ScoreModel;

  buildUpDetail:DetailStatsModel = new DetailStatsModel("BUILD-UP",40,60);
  progessionDetail:DetailStatsModel = new DetailStatsModel("PROGRESION",45,55);
  finalThirdDetail:DetailStatsModel = new DetailStatsModel("FINAL THIRD",15,85);
  counterDetail:DetailStatsModel = new DetailStatsModel("COUNTER-ATTACK",55,45);

  highPressDetail:DetailStatsModel = new DetailStatsModel("HIGH-PRESS",40,60);
  midBlockDetail:DetailStatsModel = new DetailStatsModel("MID BLOCK",45,55);
  lowBlockDetail:DetailStatsModel = new DetailStatsModel("LOW BLOCK",15,85);
  counterPressDetail:DetailStatsModel = new DetailStatsModel("COUNTER-PRESS",55,45);
  recoveryDetail:DetailStatsModel = new DetailStatsModel("RECOVERY",55,45);
  
  constructor(){
    this.screenheight = window.screen.height;
    this.localTeam = new TeamInformationModel();
    this.localTeam.displayName = 'Deportivo Alavés B';
    this.localTeam.logoIcon = '/assets/imgs/teams/deportivo-alaves-b.png';
    this.localTeam.logoPath = '/assets/imgs/teams/deportivo-alaves-b.png';
    this.visitorTeam = new TeamInformationModel();
    this.visitorTeam.displayName = 'Barakaido';
    this.visitorTeam.logoIcon = '/assets/imgs/teams/barakaido.png';
    this.visitorTeam.logoPath = '/assets/imgs/teams/barakaido.png';
    this.score = new ScoreModel(2,1,90)
  
    this.matchScoreModel = new MatchScoreModel(this.localTeam,this.visitorTeam,this.score);
    this.matchPossessionModel = new PossesionStatsModel();
    this.matchPossessionModel.localTeam = this.localTeam;
    this.matchPossessionModel.visitorTeam = this.visitorTeam;
    this.matchPossessionModel.localPercentage =24;
    this.matchPossessionModel.neutralPercentage =30;
    this.matchPossessionModel.visitorPercentage =34;
  }
}
