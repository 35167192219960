import { Component } from '@angular/core';

@Component({
  selector: 'app-menu-bar-links-widget',
  templateUrl: './menu-bar-links-widget.component.html',
  styleUrl: './menu-bar-links-widget.component.scss'
})
export class MenuBarLinksWidgetComponent {

}
