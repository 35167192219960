<div class="container-fluid">
    <div class="row mb-2">
        <div class="col">
            <h5 class="title">{{title}}</h5>
        </div>
    </div>
    <div class="row mb-1" *ngFor="let quarters of progressions, let idx = index">
        <div class="col">
            <div class="progress mb-1" style="height: 2px;" [ngStyle]="{'justify-content': visitorTeam?'end':''}" role="progressbar" aria-label="Basic example" *ngFor="let progresion of quarters.progression">
                <div class="progress-bar" [ngClass]="{'local-team':!visitorTeam,'visitor-team':visitorTeam}"  [ngStyle]="{'width': progresion.progresion+'%'}"></div>
              </div>
            <div class="row title">
                <div class="col">
                    <hr>
                </div>
                <div class="col-3">
                    {{quarters.progression.length*(idx+1)}}
                </div>
            </div>
        </div>
    </div>
</div>