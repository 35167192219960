<div class="container-fluid h-100">
    <div class="row">
        <div class="col p-0">
            <nav class="navbar bg-primary">
                <div class="container-fluid">
                  <a class="navbar-brand" href="#">
                    <h5 class="mb-0">
                        <img src="/assets/imgs/Bamas.png" style="height: 100% !important;" alt="" srcset="">
                    </h5>
                  </a>
                  <button class="btn btn-link text-white" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                    <i class="bi bi-list"></i>
                </button>
                </div>
              </nav>
        </div>
    </div>
    <div class="row mt-2 mb-2 align-items-center">
        <div class="col-6">
            <app-match-score-widget [matchScore]="matchScoreModel"></app-match-score-widget>
        </div>
        <div class="col-3"></div>
        <div class="col-3 text-end">
            <app-menu-bar-links-widget></app-menu-bar-links-widget>
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-12">
            <app-match-possession-stats-widget [matchPossession]="matchPossessionModel"></app-match-possession-stats-widget>
        </div>
    </div>
    <div class="row phase-section" [ngStyle]="{'height':screenheight -(screenheight*0.43)+'px'}">
        <div class="col-3 h-100" style="overflow-y: hidden;">
            <h6 class=" mt-2" style="color: #fff;">Phases by (x) minute</h6>
            <div class="row">
                <div class="col">
                    <app-io-match-progression-widget [title]="'In Possession'" [visitorTeam]="false"></app-io-match-progression-widget>
                </div>
                <div class="col">
                    <app-io-match-progression-widget [title]="'Out Possession'"  [visitorTeam]="false"></app-io-match-progression-widget>
                </div>
            </div>
        </div>
        <div class="col-6 text-center align-self-center">
            <h3 class="clear-title">Phases of play</h3>
            <a class="btn btn-link" style="color: #fff;" [routerLink]="['in-possession']">In Possession <i class="bi bi-arrow-right"></i></a>
            <div class="container-fluid mb-3">
                <div class="row mb-2">
                    <div class="col">
                        <app-detail-stats-widget [detailStats]="buildUpDetail"></app-detail-stats-widget>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <app-detail-stats-widget [detailStats]="progessionDetail"></app-detail-stats-widget>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <app-detail-stats-widget [detailStats]="finalThirdDetail"></app-detail-stats-widget>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <app-detail-stats-widget [detailStats]="counterDetail"></app-detail-stats-widget>
                    </div>
                </div>
               
            </div>
            <a class="btn btn-link" style="color: #fff;"  [routerLink]="['out-possession']">Out Possession <i class="bi bi-arrow-right"></i></a>
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col">
                        <app-detail-stats-widget  [detailStats]="highPressDetail"></app-detail-stats-widget>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <app-detail-stats-widget [detailStats]="midBlockDetail"></app-detail-stats-widget>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <app-detail-stats-widget [detailStats]="lowBlockDetail"></app-detail-stats-widget>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <app-detail-stats-widget [detailStats]="counterPressDetail"></app-detail-stats-widget>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <app-detail-stats-widget [detailStats]="recoveryDetail"></app-detail-stats-widget>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3 h-100" style="overflow-y: hidden;">
            <h6 class="text-end mt-2" style="color: #fff;">Phases by (x) minute</h6>
            <div class="row">
                <div class="col">
                    <app-io-match-progression-widget [title]="'In Possession'" [visitorTeam]="true"></app-io-match-progression-widget>
                </div>
                <div class="col">
                    <app-io-match-progression-widget [title]="'Out Possession'"  [visitorTeam]="true"></app-io-match-progression-widget>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2 mb-2">
        <div class="col-2">

        </div>
        <div class="col-8">

        </div>
        <div class="col-2 text-end">
            &commat;2024 Datua, DATUA IA, S.A.
        </div>
    </div>
</div>