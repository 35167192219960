import { Component } from '@angular/core';
import { VideoBlockStatsModel } from '../../../domain/model/video-block-stats-model';

@Component({
  selector: 'app-video-card-block-widget',
  templateUrl: './video-card-block-widget.component.html',
  styleUrl: './video-card-block-widget.component.scss'
})
export class VideoCardBlockWidgetComponent {

  videoBlock: VideoBlockStatsModel;

  constructor(){
    this.videoBlock = new VideoBlockStatsModel();
  }
}
