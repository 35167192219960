<div class="btn-group" role="group" aria-label="Basic example">
    <a type="button" class="btn btn-link" [routerLink]="['/']">
        <img src="/assets/imgs/icons/ball-button.png" class="icon-button">
    </a>
    <!--a type="button" class="btn btn-link" [routerLink]="['/']">
        <img src="/assets/imgs/icons/shield-button.png" class="icon-button">
    </a-->
    <a type="button" class="btn btn-link" [routerLink]="['/in-possession']">
        <img src="/assets/imgs/icons/board-button.png" class="icon-button">
    </a>
    <a type="button" class="btn btn-link" [routerLink]="['/match-videos']">
        <img src="/assets/imgs/icons/camera-button.png" class="icon-button">
    </a>
  </div>