import { Component, Input } from '@angular/core';
import { PossesionStatsModel } from '../../../domain/model/possesion-stats-model';

@Component({
  selector: 'app-match-possession-stats-widget',
  templateUrl: './match-possession-stats-widget.component.html',
  styleUrl: './match-possession-stats-widget.component.scss'
})
export class MatchPossessionStatsWidgetComponent {

    @Input() matchPossession: PossesionStatsModel;

    constructor(){
      this.matchPossession = new PossesionStatsModel();
    }
}
