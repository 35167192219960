export class ScoreModel {
    local:number = 0;
    visitor:number = 0;
    timeMatch:number = 0;

    constructor(local:number, visitor:number, timeMatch:number){
        this.local = local;
        this.visitor = visitor;
        this.timeMatch = timeMatch;
    }
}
