<div class="container-fluid ">
    <div class="row">
        <div class="col-6 detail-bar-local p-0">
            <div class="text-end detail-percentage-local pt-2 pb-2 pe-2" [ngStyle]="{'width': singleStats.localPercentage+'%'}">
                {{singleStats.localPercentage}} %
            </div>
        </div>
        <div class="col-6 detail-bar-visitor p-0">
            <div class="detail-percentage-visitor pt-2 pb-2 ps-2" [ngStyle]="{'width': singleStats.visitorPercentage+'%'}">
                {{singleStats.visitorPercentage}} %
            </div>
        </div>
    </div>
</div>