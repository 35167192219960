import { innerFrom } from "rxjs/internal/observable/innerFrom";
import { TeamInformationModel } from "./team-information-model";

export class PossesionStatsModel {
    localTeam:TeamInformationModel = new TeamInformationModel();
    visitorTeam:TeamInformationModel = new TeamInformationModel();
    localPercentage:number = 20;
    neutralPercentage:number = 10;
    visitorPercentage:number = 40;
}
