<div class="container-fluid ">
    <div class="row">
        <div class="col text-center">
            <h3> {{groupBlockStats.title}}</h3>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="time-inphase">
                <div class="time-label">
                    <i class="bi bi-clock"></i> Time in phase
                </div>
                <div class="time-percentage-minute">
                    <h5 class="mb-0">{{groupBlockStats.percentage}}%</h5>
                    <span>({{groupBlockStats.minute}}')</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col" >
            <div class="card card-body clean-shadow mb-3" *ngFor="let block of groupBlockStats.stats">
                <div class="row justify-content-center align-items-center">
                    <div class="col-3 text-center">
                        <h2 class="mb-0">{{block.count}}</h2>
                        <span class="label" *ngIf="block.percentage">{{block.percentage}}%</span>
                    </div>
                    <div class="col-7">
                        <h5 class="mb-0">{{block.label}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>