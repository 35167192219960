<div class="card card-body card-clear mb-2 p-2" style="border: solid 3px #ffffff;">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-2 text-center time-block pt-2 pb-2">
                <i class="bi bi-clock"></i>
                <h5>{{videoBlock.time}}</h5>
            </div>
            <div class="col-10">
                <strong class="mb-2">{{videoBlock.title}}</strong>
                <div class="row">
                    <div class="col-3">
                        <span class="badge bg-white text-black">{{videoBlock.duration}} sec</span>
                    </div>
                    <div class="col-5">
                        <span class="badge bg-primary me-1" style="background-color: var(--bs-secondary) !important;">{{videoBlock.ioPosession}}</span>
                    </div>
                    <div class="col-4">
                        <span class="badge bg-white " style="color: var(--bs-secondary) !important;">{{videoBlock.blockStats}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>