import { Component, Input } from '@angular/core';
import { GroupBlockStatsModel } from '../../../domain/model/group-block-stats-model';
import { LabelStatsModel } from '../../../domain/model/label-stats-model';

@Component({
  selector: 'app-group-block-stats-widget',
  templateUrl: './group-block-stats-widget.component.html',
  styleUrl: './group-block-stats-widget.component.scss'
})
export class GroupBlockStatsWidgetComponent {

  @Input() groupBlockStats:GroupBlockStatsModel;
  labelStat:LabelStatsModel;

  constructor(){
    let stats = new Array<LabelStatsModel>();
    this.labelStat = new LabelStatsModel('0','Label','');
    stats.push(this.labelStat);

    this.groupBlockStats = new GroupBlockStatsModel('Title',0,0,stats);
  }

}
