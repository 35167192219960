
    <div class="container-fluid">
        <div class="row">
            <div class="col title-flex">
                <i class="bi bi-circle-fill me-2" [ngClass]="{'local-team-color':blockStats.team =='local', 'visitor-team-color':blockStats.team == 'visitor'}"></i><h3 class="mb-0"> {{blockStats.title}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="time-inphase">
                    <div class="time-label">
                        <i class="bi bi-clock"></i> Time in phase
                    </div>
                    <div class="time-percentage-minute">
                        <h5 class="mb-0">{{blockStats.percentage}}%</h5>
                        <span>({{blockStats.minute}}')</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card card-body clean-shadow">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-3 text-end">
                            <h2 class="mb-0">{{blockStats.count}}</h2>
                        </div>
                        <div class="col-6">
                            <h5 class="mb-0">{{blockStats.labelCounts}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>