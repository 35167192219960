import { ScoreModel } from "./score-model";
import { TeamInformationModel } from "./team-information-model";

export class MatchScoreModel {
    localTeam:TeamInformationModel = new TeamInformationModel();
    visitorTeam:TeamInformationModel = new TeamInformationModel();
    score: ScoreModel = new ScoreModel(0,0,0);

    constructor(localTeam:TeamInformationModel, visitorTeam:TeamInformationModel, score: ScoreModel){
        this.localTeam = localTeam;
        this.visitorTeam = visitorTeam;
        this.score = score;
    }

}
