import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatchScoreModel } from '../../domain/model/match-score-model';
import { TeamInformationModel } from '../../domain/model/team-information-model';
import { ScoreModel } from '../../domain/model/score-model';
import { BlockStatsModel } from '../../domain/model/block-stats-model';
import { GroupBlockStatsModel } from '../../domain/model/group-block-stats-model';
import { LabelStatsModel } from '../../domain/model/label-stats-model';

@Component({
  selector: 'app-in-possession-page',
  templateUrl: './in-possession-page.component.html',
  styleUrl: './in-possession-page.component.scss'
})
export class InPossessionPageComponent {
  screenheight:number;

  inOutPossession:boolean = false;

  matchScoreModel:MatchScoreModel;

  localTeam:TeamInformationModel;
  visitorTeam:TeamInformationModel;
  score: ScoreModel;

  buildUpBlockStats: BlockStatsModel;
  progressionBlockStats: BlockStatsModel;

  counterAttackBlockModel: GroupBlockStatsModel;
  finalthirdBlockModel: GroupBlockStatsModel;
  
  constructor(private _router:Router){
    this.screenheight = window.screen.height;
    this.localTeam = new TeamInformationModel();
    this.localTeam.displayName = 'Deportivo Alavés B';
    this.localTeam.logoIcon = '/assets/imgs/teams/deportivo-alaves-b.png';
    this.localTeam.logoPath = '/assets/imgs/teams/deportivo-alaves-b.png';
    this.visitorTeam = new TeamInformationModel();
    this.visitorTeam.displayName = 'Barakaido';
    this.visitorTeam.logoIcon = '/assets/imgs/teams/barakaido.png';
    this.visitorTeam.logoPath = '/assets/imgs/teams/barakaido.png';
    this.score = new ScoreModel(2,1,90)
  
    this.matchScoreModel = new MatchScoreModel(this.localTeam,this.visitorTeam,this.score);

    this.buildUpBlockStats = new BlockStatsModel('Build-Up',46,25,14,'local','Build-ups');
    this.progressionBlockStats = new BlockStatsModel('Progression',46,25,10,'visitor','Progressions');

    let statsAttack = new Array<LabelStatsModel>();
    let attackLabelStat = new LabelStatsModel('8','Counter attacks','');
    let potentialLabelStat = new LabelStatsModel('6/9','Potential counter attacks','66');
    statsAttack.push(attackLabelStat);
    statsAttack.push(potentialLabelStat);

    this.counterAttackBlockModel = new GroupBlockStatsModel('Counter-Attack',9,5,statsAttack);

    let statsFinal = new Array<LabelStatsModel>();
    let finalLabelStat = new LabelStatsModel('9','Final thirds','');
    statsFinal.push(finalLabelStat);
    
    this.finalthirdBlockModel = new GroupBlockStatsModel('Final Third',16,9,statsFinal);

  }

  goOutPossession(){
    this._router.navigate(['/out-possession'])
  }
}
