import { LabelStatsModel } from "./label-stats-model";

export class GroupBlockStatsModel {
    title:string= "TITULO";
    percentage:number = 0;
    minute:number = 0;
    stats:Array<LabelStatsModel> = Array<LabelStatsModel>()

    constructor(title:string, percentage:number, minute:number, stats:Array<LabelStatsModel>){
        this.title = title;
        this.percentage = percentage;
        this.minute = minute;
        this.stats = stats;
    }
}
