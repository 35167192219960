export class DetailStatsModel {
    detail:string ='BUILD-UP';
    localPercentage:number = 40;
    visitorPercentage:number = 60;

    constructor(detail:string, local:number, visitor:number){
        this.detail = detail;
        this.localPercentage = local;
        this.visitorPercentage = visitor;
    }
}
