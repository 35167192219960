import { Component } from '@angular/core';
import { SingleBarStatsModel } from '../../../domain/model/single-bar-stats-model';

@Component({
  selector: 'app-single-bar-stats-widget',
  templateUrl: './single-bar-stats-widget.component.html',
  styleUrl: './single-bar-stats-widget.component.scss'
})
export class SingleBarStatsWidgetComponent {

  singleStats: SingleBarStatsModel;

  constructor(){
    this.singleStats = new SingleBarStatsModel();
  }

}
