export class LabelStatsModel {
    count:string = '0';
    percentage:string = '';
    label:string = "Label"

    constructor(count:string, label:string, percentage:string){
        this.count = count;
        this.label = label;
        this.percentage = percentage;
    }
}
