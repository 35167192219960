import { Component, Input } from '@angular/core';
import { MatchScoreModel } from '../../../domain/model/match-score-model';
import { TeamInformationModel } from '../../../domain/model/team-information-model';
import { ScoreModel } from '../../../domain/model/score-model';

@Component({
  selector: 'app-match-score-widget',
  templateUrl: './match-score-widget.component.html',
  styleUrl: './match-score-widget.component.scss'
})
export class MatchScoreWidgetComponent {

  @Input() matchScore:MatchScoreModel;

  constructor(){
    let localTeam = new TeamInformationModel();
    let visitorTeam = new TeamInformationModel();
    let score = new ScoreModel(0,0,0)
    this.matchScore = new MatchScoreModel(localTeam,visitorTeam,score);
  }
}
