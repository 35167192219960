<div class="container-fluid h-100">
    <div class="row">
        <div class="col p-0">
            <nav class="navbar bg-primary">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#">
                        <h5 class="mb-0">
                            <img src="/assets/imgs/Bamas.png" style="height: 100% !important;" alt="" srcset="">
                        </h5>
                    </a>
                    <button class="btn btn-link text-white" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                        <i class="bi bi-list"></i>
                    </button>
                </div>
            </nav>
        </div>
    </div>
    <div class="row mt-2 mb-2 align-items-center">
        <div class="col-6">
            <app-match-score-widget [matchScore]="matchScoreModel"></app-match-score-widget>
        </div>
        <div class="col-3">
           
           
        </div>
        <div class="col-3 text-end">
            <app-menu-bar-links-widget></app-menu-bar-links-widget>
        </div>
    </div>
        <div class="row phase-section" [ngStyle]="{'height':screenheight  -(screenheight*0.29)+'px'}">
            <div class="col align-self-center">
                <div class="container">
                    <div class="row">
                        <div class="col-7">
                            <div class="row mb-3">
                                <div class="col-9">
                                    <h5 class="text-white"><strong *ngIf="video3D2d == false">3D</strong><strong *ngIf="video3D2d == true">2D</strong> - REFRound15AlavesBarakaido20231210_00
                                    </h5>
                                    <div class="row">
                                        <div class="col-8 text-start">
                                            <div class="title-flex text-white mb-2">
                                                <i class="bi bi-circle-fill me-2" style="color:var(--bs-localteam)"></i>
                                                <h6 class="team-float mb-0 me-2"> {{localTeam.displayName}}</h6>
                                                <span class="badge bg-primary me-1">In Possesion</span> <span
                                                    class="badge bg-white text-black">Build-Up</span>
                                            </div>
                                            <div class="title-flex text-white">
                                                <i class="bi bi-circle-fill me-2"
                                                    style="color:var(--bs-visitorteam)"></i>
                                                <h6 class="mb-0 me-2"> {{visitorTeam.displayName}}</h6>
                                                <span class="badge bg-secondary me-1"
                                                    style="background-color: var(--bs-secondary) !important;">Out
                                                    Possesion</span> <span class="badge bg-white "
                                                    style="color: var(--bs-secondary) !important;">Mid Block</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 switch-select">
                                    <label class="form-check-label text-white me-3" for="flexSwitchCheckDefault">3D</label>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input form-check-input-clean" type="checkbox" role="switch"
                                            id="flexSwitchCheckDefault" [(ngModel)]="video3D2d">
                                    </div>
                                    <label class="form-check-label ms-2 text-white" for="flexSwitchCheckDefault">2D</label>
                                </div>
                            </div>
                            <div class="row" style="height: 80%">
                                <div class="col h-100">
                                    <div class="card card-body h-100 p-1" *ngIf="video3D2d == false">
                                        <div class="video-screen">
                                            <video controls autoplay>
                                                <source src="/assets/video/barakaldo_multivera_gol1_izquierda.mp4" type="video/mp4">
                                              </video>
                                        </div>
                                        
                                    </div>

                                    <div class="card card-body h-100 p-1" *ngIf="video3D2d == true">
                                        <div class="video-screen">
                                        <video class="video-screen" controls autoplay>
                                            <source src="/assets/video/projection.mp4"  type="video/mp4">
                                          </video>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="card card-body card-clear p-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col">
                                            <h3>Match Videos</h3>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col">
                                            <h5>Phase of Plays</h5>
                                        </div>
                                        <div class="col">
                                            <div class="input-group">
                                                <select class="form-select" id="inputGroupSelect01">
                                                    <option >Out Possession</option>
                                                    <option selected>In Possession</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <app-video-card-block-widget></app-video-card-block-widget>
                                            <app-video-card-block-widget></app-video-card-block-widget>
                                            <app-video-card-block-widget></app-video-card-block-widget>
                                            <app-video-card-block-widget></app-video-card-block-widget>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row mt-2 mb-2">
            <div class="col-2">

            </div>
            <div class="col-8">

            </div>
            <div class="col-2 text-end">
                &commat;2024 Datua, DATUA IA, S.A.
            </div>
        </div>
</div>