import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatchDashboardComponent } from './presentation/match-dashboard/match-dashboard.component';
import { MatchScoreWidgetComponent } from './presentation/widget/match-score-widget/match-score-widget.component';
import { FormsModule } from '@angular/forms';
import { MatchPossessionStatsWidgetComponent } from './presentation/widget/match-possession-stats-widget/match-possession-stats-widget.component';
import { DetailStatsWidgetComponent } from './presentation/widget/detail-stats-widget/detail-stats-widget.component';
import { InPossessionPageComponent } from './presentation/in-possession-page/in-possession-page.component';
import { GroupBlockStatsWidgetComponent } from './presentation/widget/group-block-stats-widget/group-block-stats-widget.component';
import { BulletStatsWidgetComponent } from './presentation/widget/bullet-stats-widget/bullet-stats-widget.component';
import { OutPossessionPageComponent } from './presentation/out-possession-page/out-possession-page.component';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { SingleBarStatsWidgetComponent } from './presentation/widget/single-bar-stats-widget/single-bar-stats-widget.component';
import { MenuBarLinksWidgetComponent } from './presentation/widget/menu-bar-links-widget/menu-bar-links-widget.component';
import { IoMatchProgressionWidgetComponent } from './presentation/widget/io-match-progression-widget/io-match-progression-widget.component';
import { MatchVideosPageComponent } from './presentation/match-videos-page/match-videos-page.component';
import { VideoCardBlockWidgetComponent } from './presentation/widget/video-card-block-widget/video-card-block-widget.component';

@NgModule({
  declarations: [
    AppComponent,
    MatchDashboardComponent,
    MatchScoreWidgetComponent,
    MatchPossessionStatsWidgetComponent,
    DetailStatsWidgetComponent,
    InPossessionPageComponent,
    GroupBlockStatsWidgetComponent,
    BulletStatsWidgetComponent,
    OutPossessionPageComponent,
    SingleBarStatsWidgetComponent,
    MenuBarLinksWidgetComponent,
    IoMatchProgressionWidgetComponent,
    MatchVideosPageComponent,
    VideoCardBlockWidgetComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BaseChartDirective,
  ],
  providers: [
    provideCharts(withDefaultRegisterables())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
