<div class="offcanvas offcanvas-end side-bk" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-body p-2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-9">
            <h5 class="offcanvas-title" id="offcanvasRightLabel">Menu</h5>
          </div>
          <div class="col-3 text-end">
            <button type="button" class="btn btn-link" style="color: #fff;" data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
<router-outlet />
