<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-5 text-center">
            <h3>Possession</h3>

            <div class="match-possession-bar">
                <div class="match-percentage-bars">
                    <div class="possession-bar possession-bar-end">
                        <img [src]="matchPossession.localTeam.logoIcon" class="team-logo team-local" alt="logo">
                        <div class="local-percentage-bar pt-3 pb-3" [ngStyle]="{'width': matchPossession.localPercentage+'%'}">{{matchPossession.localPercentage}} %</div>
                    </div>
                    <div class="possession-bar" [ngStyle]="{'width': matchPossession.neutralPercentage+'%'}">
                        <div class="neutral-percentage-bar pt-3 pb-3">{{matchPossession.neutralPercentage}} %</div>
                    </div>
                    <div class="possession-bar">
                        <img [src]="matchPossession.visitorTeam.logoIcon" class="team-logo team-visitor" alt="logo">
                        <div class="visitor-percentage-bar  pt-3 pb-3" [ngStyle]="{'width': matchPossession.visitorPercentage+'%'}">{{matchPossession.visitorPercentage}} %</div>
                    </div>
                   
                </div>

            </div>
        </div>
    </div>
</div>