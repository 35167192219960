import { Component, Input } from '@angular/core';
import { IoQuarterProgressionModel } from '../../../domain/model/io-quarter-progression-model';
import { IoMinuteProgressionModel } from '../../../domain/model/io-minute-progression-model';

@Component({
  selector: 'app-io-match-progression-widget',
  templateUrl: './io-match-progression-widget.component.html',
  styleUrl: './io-match-progression-widget.component.scss'
})
export class IoMatchProgressionWidgetComponent {

  @Input() title:string ='In Possession';
  @Input() visitorTeam:boolean = false;
  progressions:Array<IoQuarterProgressionModel>;

  constructor(){
    this.progressions = new Array<IoQuarterProgressionModel>();
    this.buildProgessionTest();
  }

  buildProgessionTest(){
    this.progressions = new Array<IoQuarterProgressionModel>();
    let ioQuartetModel = new IoQuarterProgressionModel();
    for (let index = 0; index < 90; index++) {
      let ioMinute = new IoMinuteProgressionModel();
        ioMinute.minute = index;
        ioMinute.progresion = Math.random()*100;
      if(ioQuartetModel.progression.length < 20){
        ioQuartetModel.progression.push(ioMinute);
        
      } else {
        this.progressions.push(ioQuartetModel);
        ioQuartetModel = new IoQuarterProgressionModel();
      }
    }
    if(ioQuartetModel.progression.length< 20){
      this.progressions.push(ioQuartetModel);
    }
  }

}
