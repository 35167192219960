import { Component, Input } from '@angular/core';
import { BlockStatsModel } from '../../../domain/model/block-stats-model';

@Component({
  selector: 'app-bullet-stats-widget',
  templateUrl: './bullet-stats-widget.component.html',
  styleUrl: './bullet-stats-widget.component.scss'
})
export class BulletStatsWidgetComponent {

  @Input() blockStats: BlockStatsModel;

  constructor(){
    this.blockStats = new BlockStatsModel('Stats',0,0,0,'neutral','Stats');
  }
}
