<div class="container-fluid">
    <div class="row">
        <div class="col-4 detail-bar-local p-0">
            <div class="detail-percentage-local text-end pe-1  pt-1 pb-1" [ngStyle]="{'width': detailStats.localPercentage+'%'}">
                {{detailStats.localPercentage}} %
            </div>
        </div>
        <div class="col-4 detail-title pt-1 pb-1">
            {{detailStats.detail}}
        </div>
        <div class="col-4 detail-bar-visitor p-0">
            <div class="detail-percentage-visitor text-start ps-1  pt-1 pb-1" [ngStyle]="{'width': detailStats.visitorPercentage+'%'}">
                {{detailStats.visitorPercentage}} %
            </div>
        </div>
    </div>
</div>