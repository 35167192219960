export class BlockStatsModel {
    title:string= "TITULO";
    percentage:number = 0;
    minute:number = 0;
    count:number = 0;
    team:string = 'neutral';
    labelCounts:string = 'Label';

    constructor(title:string, percentage:number, minute:number, count:number, team:string,labelCounts:string){
        this.title = title;
        this.percentage = percentage;
        this.minute = minute;
        this.count = count;
        this.team = team;
        this.labelCounts = labelCounts;
    }
}
