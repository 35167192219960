import { Component } from '@angular/core';
import { MatchScoreModel } from '../../domain/model/match-score-model';
import { TeamInformationModel } from '../../domain/model/team-information-model';
import { ScoreModel } from '../../domain/model/score-model';

@Component({
  selector: 'app-match-videos-page',
  templateUrl: './match-videos-page.component.html',
  styleUrl: './match-videos-page.component.scss'
})
export class MatchVideosPageComponent {

  screenheight:number;

  matchScoreModel:MatchScoreModel;

  localTeam:TeamInformationModel;
  visitorTeam:TeamInformationModel;
  score: ScoreModel;

  video3D2d:boolean = false;

  constructor(){
    this.screenheight = window.screen.height;
    this.localTeam = new TeamInformationModel();
    this.localTeam.displayName = 'Deportivo Alavés B';
    this.localTeam.logoIcon = '/assets/imgs/teams/deportivo-alaves-b.png';
    this.localTeam.logoPath = '/assets/imgs/teams/deportivo-alaves-b.png';
    this.visitorTeam = new TeamInformationModel();
    this.visitorTeam.displayName = 'Barakaido';
    this.visitorTeam.logoIcon = '/assets/imgs/teams/barakaido.png';
    this.visitorTeam.logoPath = '/assets/imgs/teams/barakaido.png';
    this.score = new ScoreModel(2,1,90)
  
    this.matchScoreModel = new MatchScoreModel(this.localTeam,this.visitorTeam,this.score);
  }
}
